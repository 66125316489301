<template>
    <div class="main">
        <div class="search">
            <div class="refresh" @click="getCasinoBetList"><i class="fa fa-refresh"></i></div>
            <el-input type="text" size="mini" v-model="honorCasinoBet.nickname" placeholder="닉네임을 입력하세요" style="width: 150px"></el-input>
            <date-selector-comp @setStartDate="setStartDate" @setEndDate="setEndDate"></date-selector-comp>
            <el-button type="primary" size="mini" style="margin-left: 5px" @click="getCasinoBetList">검색
            </el-button>
            <el-select v-model="honorCasinoBet.transType" @change="getCasinoBetList" size="mini"
                       placeholder="결과선택"
                       style="width:100px;margin-left: 5px">
                <el-option key="13" label="전체" :value="null">전체</el-option>
                <el-option key="16" label="BET" :value="'BET'">BET</el-option>
                <el-option key="15" label="당첨" :value="'WIN'">당첨</el-option>
            </el-select>
        </div>

        <div class="data" style="overflow-y: scroll;max-height: 750px">
            <table style="width: 100%">
                <tr>
                    <th style="width: 10%">TSID</th>
                    <th style="width: 10%">닉네임</th>
                    <th style="width: 10%">CUID</th>
                    <th style="width: 20%">Time</th>
                    <th style="width: 5%">ThirdParty</th>
                    <th style="width: 10%">GameID</th>
                    <th style="width: 5%">Amount</th>
                    <th style="width: 5%">TransType</th>
                    <th style="width: 5%">GiveBacked</th>
                </tr>
                <tr v-for="item in honorCasinoBetList">
                    <td>
                        {{item.tsid}}
                    </td>
                    <td>
                        <el-button size="mini" type="success" @click="editUser(item.uid,managerConst.MODE_EDIT)">
                            {{item.nickname}}
                        </el-button>
                    </td>
                    <td>
                        {{item.userId}}
                    </td>
                    <td>
                        {{item.transTime | datef('MM월DD일 HH:mm:ss')}}
                    </td>

                    <td>
                        {{item.thirdParty}}
                    </td>
                    <td>
                        {{item.gameId}}
                    </td>
                    <td>
                        <span>{{item.amount | comma}}</span>
                    </td>
                    <td>
                        <span v-if="item.amount > 0 && item.transType == 'WIN'" class="text-blue">
                          당첨
                        </span>
                        <span v-if="item.amount > 0 && item.transType == 'BET'" class="">
                          BET
                        </span>
                    </td>
                    <td>
                        <span v-if="item.givebacked == 0">NOT</span>
                        <span v-if="item.givebacked == 1" class="text-blue">YES</span>
                    </td>
                </tr>
            </table>
        </div>
        <div class="pagePanel">
            <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="pageNum"
                    :page-sizes="[20, 100, 150, 200]"
                    :page-size="20"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="pageTotal">
            </el-pagination>
        </div>

    </div>
</template>

<script>

    import OnlineUserComp from "../../components/administrator/OnlineUserComp";
    import TodayStatisticComp from "../../components/administrator/TodayStatisticComp";
    import ManagerTopbarComp from "../../components/administrator/ManagerTopbarComp";
    import {Loading} from "element-ui";
    import {getHonorCasinoBetlist, sysData} from "../../network/manager/honorCasinoRequest";
    import {manager} from "../../common/administrator/managerMixin";
    import DateSelectorComp from "../../components/administrator/DateSelectorComp";
    import Vue from "vue";
    import UserEditToast from "../../components/administrator/UserPanel/UserEditToast";
    import {getXimaxBetlist} from "../../network/manager/ximaxCasinoRequest";
    Vue.prototype.$userEditToast = UserEditToast
    export default {
        name: "ManagerCasinoBetXimax",
        mixins: [manager],
        components: {
            DateSelectorComp,
            ManagerTopbarComp, TodayStatisticComp, OnlineUserComp
        },
        data() {
            return {
                startDate: '',
                endDate: '',
                honorCasinoBet: {partnerId: null},
                honorCasinoBetList: [],
                pageNum: 1,
                pageSize: 20,
                pageTotal: 0,
                totalBetCount: 0,
                totalBetCash: 0,
                totalBetWinCash: 0,
                pickerOptions: {
                    shortcuts: [{
                        text: '오늘',
                        onClick(picker) {
                            picker.$emit('pick', new Date());
                        }
                    }, {
                        text: '어제',
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(date.getTime() - 3600 * 1000 * 24);
                            picker.$emit('pick', date);
                        }
                    }, {
                        text: '일주일전',
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', date);
                        }
                    }, {
                        text: '15일전',
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(date.getTime() - 3600 * 1000 * 24 * 14);
                            picker.$emit('pick', date);
                        }
                    }]
                },

            }
        },
        methods: {
            getCasinoBetList() {
                let loadingInstance = Loading.service({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                this.honorCasinoBet.startDate = this.startDate
                this.honorCasinoBet.endDate = this.endDate
                getXimaxBetlist(this.honorCasinoBet, this.pageNum, this.pageSize).then(res => {
                    this.pageTotal = res.data.total;
                    this.honorCasinoBetList = res.data.data;
                    loadingInstance.close();
                })

            },
            editUser(userId, mode) {
                this.$userEditToast({'userId': userId, 'mode': mode})
            },
            handleSizeChange(val) {
                this.pageNum = 1;
                this.pageSize = val;
                this.getCasinoBetList();
            },
            handleCurrentChange(val) {
                this.pageNum = val;
                this.getCasinoBetList();
            },
            setStartDate(date) {
                this.startDate = this.$moment(date).format('yyyy-MM-DD');
            },
            setEndDate(date) {
                this.endDate = this.$moment(date).format('yyyy-MM-DD')
            },
            setPartnerId(parterId) {
                this.leisureGameBet.partnerId = parterId
                this.search();
            },
        },
        created() {
            this.startDate = null
            this.endDate = null
            this.getCasinoBetList()
        },
    }
</script>

<style scoped>

</style>
import { render, staticRenderFns } from "./ManagerCasinoBetXimax.vue?vue&type=template&id=58835eb6&scoped=true&"
import script from "./ManagerCasinoBetXimax.vue?vue&type=script&lang=js&"
export * from "./ManagerCasinoBetXimax.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58835eb6",
  null
  
)

export default component.exports